<template>
  <div>

    <div class="deta">
      <p class="title">就诊人信息</p>

      <el-row>
        <el-col :span="6">
          <p class="info">就诊人姓名：{{ deta.name }}</p>
        </el-col>
        <el-col :span="6">
          <p class="info">就诊人性别：{{ deta.gender == 0 ? '女' : '男' }}</p>
        </el-col>
        <el-col :span="6">
          <p class="info">就诊人手机号：{{ deta.phoneNo }}</p>
        </el-col>
        <el-col :span="6">
          <p class="info">就诊人身份证号：{{ deta.idCardNo }}</p>
        </el-col>
      </el-row>

      <p class="title">
        陪诊服务
      </p>

      <el-row>
        <el-col :span="( deta.appointDept ? Math.floor(24/5) : Math.floor(24/4) )">
          <p class="info">服务项目：{{ deta.productName }}</p>
        </el-col>
        <el-col :span="( deta.appointDept ? Math.floor(24/5) : Math.floor(24/4) )">
          <p class="info">预约日期：{{ deta.appointmentDate }}</p>
        </el-col>
        <el-col :span="( deta.appointDept ? Math.floor(24/5) : Math.floor(24/4) )">
          <p class="info">预约时段：{{ deta.appointmentTime }}</p>
        </el-col>
        <el-col :span="( deta.appointDept ? Math.floor(24/5) : Math.floor(24/4) )">
          <p class="info">预约医院：{{ deta.hospitalName }}</p>
        </el-col>
        <el-col v-if="deta.appointDept" :span="( deta.appointDept ? Math.floor(24/5) : Math.floor(24/4) )">
          <p class="info">预约科室：{{ deta.appointDept }}</p>
        </el-col>
      </el-row>


      <!--    <div class="ready">-->
      <!--      <p>胃肠道准备：</p>-->
      <!--      <el-image-->
      <!--          style="width: 100px; height: 100px"-->
      <!--          :src="url"-->
      <!--          :preview-src-list="srcList">-->
      <!--      </el-image>-->
      <!--    </div>-->


      <div class="deta_form">
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="100px"
                 :hide-required-asterisk="true" >
          <div style="width: 30%">
            <el-form-item label="胃肠道准备" prop="name" v-if="deta.colonoscopyPrepareImg" >
              <el-image
                  :src="deta.colonoscopyPrepareImg"
                  :preview-src-list="[deta.colonoscopyPrepareImg]">
              </el-image>
            </el-form-item>
            <el-form-item label="服务进度" prop="status">
              <el-select v-model="form.status" :disabled="deta.status == 2" clearable placeholder="请选择">
                <el-option
                    v-for="items in Record_colum"
                    :key="items.dictKey"
                    :label="items.dictValue"
                    :value="items.dictKey">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="陪诊记录" prop="content">
              <el-input type="textarea" v-model="form.content" clearable placeholder="请输入套餐介绍"></el-input>
            </el-form-item>

            <el-image-viewer
                v-if="showViewerDemo"
                :on-close="closeViewerDemo"
                :url-list="viewerImgListDemo" />

            <el-form-item :label="item.reportTypeName + '报告上传'" prop="name" v-for="item in reports_uploadarr" :key="item.ref">
<!--              <el-upload-->
<!--                  class="upload-demo"-->
<!--                  :action="action"-->
<!--                  :headers="headers"-->
<!--                  :data="data"-->
<!--                  :before-remove="beforeRemove"-->
<!--                  :on-preview="handlePreview"-->
<!--                  :before-upload="beforeUpload"-->
<!--                  :on-success="onSuccess"-->
<!--                  multiple-->
<!--                  accept=".pdf"-->
<!--                  :file-list="fileList">-->
<!--                <el-button size="small" type="primary">点击上传</el-button>-->
<!--                <div slot="tip" class="el-upload__tip">只能上传pdf文件</div>-->
<!--              </el-upload>-->
              <el-upload
                  :ref="item.reportType"
                  class="upload-demo"
                  :action="action"
                  :headers="headers"
                  :data="data"
                  list-type="picture-card"
                  :before-remove="beforeRemove"
                  :on-preview="handlePreview"
                  :before-upload="beforeUpload"
                  :on-success="(value)=> onSuccess(value,item)"
                  multiple
                  accept=".jpg,.png,.jpeg,.bmp"
                  :file-list="item.imgs">
                <i slot="default" class="el-icon-plus"></i>
                <div class="el-upload__tip" slot="tip">只能上传jpg/jpeg/png/bmp文件</div>
                <div class="upload_image" slot="file" slot-scope="{file}">
                  <el-image
                      v-if="file.url"
                      :src="file.url">
                  </el-image>

                  <span class="el-upload-list__item-actions">
                    <span @click="( display_click_left ? changeLeft(file,item.reportType,item) : '' )" class="el-icon-arrow-left"></span>

                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreviewDemo(file,item)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span
                        class="el-upload-list__item-delete"
                        @click="handleRemoveProductBannerDemo(file,item.reportType,item)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>

                    <span @click="(display_click_right ? changeRight(file,item.reportType,item) : '' )" class="el-icon-arrow-right"></span>

                  </span>

                </div>
              </el-upload>
            </el-form-item>

            <el-image-viewer
                v-if="showViewer"
                :on-close="closeViewer"
                :url-list="viewerImgList" />
            <el-form-item label="费用单上传" prop="name">
              <el-upload
                  ref="uploadProductBanner"
                  list-type="picture-card"
                  accept=".jpg,.png,.jpeg,.bmp"
                  :data="data"
                  :action="actionurl"
                  :file-list="fileListimg"
                  :headers="headers"
                  :before-remove="beforeRemoveBanner"
                  :on-success="handleAvatarSuccessPicProductBanner"
                  :before-upload="beforeAvatarUploadPicProductBanner">
                <i slot="default" class="el-icon-plus"></i>
                <div class="el-upload__tip" slot="tip">
                  <p>只能上传jpg/jpeg/png/bmp文件</p>
                  <p style="color: red;">收费单（内部资料请勿外传）</p>
                </div>
                <div class="upload_image" slot="file" slot-scope="{file}">
                  <!--                <img-->
                  <!--                    class="el-upload-list__item-thumbnail"-->
                  <!--                    :src="file.url" alt=""-->
                  <!--                >-->
                  <el-image
                      v-if="file.url"
                      :src="file.url">
                  </el-image>

                <span class="el-upload-list__item-actions">
                  <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                      class="el-upload-list__item-delete"
                      @click="handleRemoveProductBanner(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <el-form-item>
            <div style="display: flex; justify-content: flex-end;">
              <el-button size="small" @click="calcel" >取消</el-button>
              <el-button size="small" @click="noClaim" v-if="deta.status == 0" type="primary">取消认领</el-button>
              <el-button size="small" @click="onSubmit(form)" type="primary">保存</el-button>
              <el-button size="small" @click="submit()" type="primary">提交报告</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog
        class="dia"
        title="请选择上传文件的报告类型"
        :visible.sync="updia"
        width="25%"
        :show-close="false"
        :close-on-click-modal="false"
        :before-close="handleClose"
        center>
      <el-form ref="form_upload"
               :model="form_upload"
               :rules="form_upload_rules"
               label-width="80px"
               :hide-required-asterisk="true" >
        <el-form-item label="报告类型" prop="reportType">
          <el-select v-model="form_upload.reportType" clearable placeholder="请选择">
            <el-option
                v-for="items in reportType_list"
                :key="items.dictKey"
                :label="items.dictValue"
                :value="items.dictKey">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="display: flex; justify-content: flex-end;">
          <el-button @click="form_upload_click(form_upload)" type="primary">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

<!--    <pdf :src="pdf_src"></pdf>-->
  </div>
</template>

<script>
// import pdf from 'vue-pdf'
export default {
  name: "OperateDeta",
  data() {
    return{
      url: null,
      srcList: [],
      form: {
        id: "",
        status: "",
        content: "",
        reports: [],
        receipts: [],
        delReports: [],
        delReceipts: [],
      },
      rules: {
        status: [
          { required: true, message: '请选择服务进度', trigger: 'blur' },
        ],
        content: []
      },
      // 上传结果
      action: process.env.VUE_APP_APIURL + "operate/report/upload",
      headers: {
        authorization: this.$store.state.token
      },
      data: {
        id: null
      },
      // 上传结果前的报告类型
      updia: false,
      form_upload: {
        reportType: null,
      },
      form_upload_rules: {
        reportType: [
          { required: true, message: '请选择上传文件的报告类型', trigger: 'blur' },
        ]
      },
      reports: {
        id: null,
        path: null,
        reportType: null,
      },
      // 上传图片
      actionurl: process.env.VUE_APP_APIURL + "operate/receipt/upload",
      fileListimg: [],
      showViewer: false,
      receipts: {
        id: null,
        path: null,
      },
      Record_colum: [
        {
          dictKey: "1",
          dictValue: "已开始"
        },
        {
          dictKey: "2",
          dictValue: "已完成"
        }
      ],
      deta: {},
      fileList: [],
      // 报告类型字典
      reportType_list: [],
      // 上传变量
      upload_load: true,
      viewerImgList: null,
      viewerImgListDemo: [],
      showViewerDemo: false,
      reports_uploadarr: [],
      // 上下移动重复点击
      display_click_left: true,
      display_click_right: true,
    }
  },
  created() {
    if(this.$route.query.state == 0 || this.$route.query.state == 1) {
      console.log(this.$route.query.id);
      this.get_deta_appointment(this.$route.query.id);
    }else {
      this.get_deta_follow(this.$route.query.id);
    }
    this.getReportTypeList();
  },
  mounted() {

  },
  methods: {
    // 获取报告类型
    getReportTypeList() {
      if(this.$route.query.state == 0 || this.$route.query.state == 1) {
        this.$get("base/dict/report-type/appoint")
          .then(res => {
            if(res) {
              console.log(res);
              this.reportType_list= res.data;
            }
          })
      }else {
        this.$get("base/dict/report-type/follow")
            .then(res => {
              if(res) {
                console.log(res);
                this.reportType_list= res.data;
              }
            })
      }
    },
    // 取消
    calcel() {
      this.$router.push({
        path: "/OperateWork",
        query: {
          activeName: this.$route.query.state
        }
      })
    },
    // 提交
    submit() {

      this.reports_uploadarr.forEach((item) => {
        this.form.reports.forEach((items) => {
          if(item.reportType == items.reportType) {
            this.$refs[item.reportType][0].uploadFiles.map((itemss,indexss) => {
              var path = ""
              if(itemss.path) {
                path= itemss.path;
              }else {
                path= itemss.response.data.path;
              }
              items.imgs.forEach((itemsss,indexsss) => {
                if(itemsss.path == path) {
                  this.drag(items.imgs,indexsss,indexss)
                }
              })
            })
          }
        })

      })

      this.$refs.form.validate((valid) => {
        if(valid) {
          console.log(this.form);

          let obj ={};
          obj["id"]= this.deta.id;
          obj["accompanyId"]= this.deta.accompanyId;
          console.log(obj)


          if(this.$route.query.state == 0) {
            this.$post("operate/appointment/accompany/edit",this.form)
                .then(res => {
                  if(res) {
                    console.log(res)

                    this.$post("operate/appointment/commit",obj)
                        .then(res => {
                          if(res) {
                            console.log(res);
                            this.$router.push({
                              path: "/OperateWork",
                              query: {
                                activeName: this.$route.query.state
                              }
                            })
                          }
                        })

                  }
                })
          }else if(this.$route.query.state == 2){
            this.$post("operate/follow/accompany/edit",this.form)
                .then(res => {
                  if(res) {
                    console.log(res)

                    this.$post("operate/follow/commit",obj)
                        .then(res => {
                          if(res) {
                            console.log(res);
                            this.$router.push({
                              path: "/OperateWork",
                              query: {
                                activeName: this.$route.query.state
                              }
                            })
                          }
                        })

                  }
                })
          }
          // }else {
          //   this.$message.warning("报告上传未完成不可操作保存");
          // }

        }
      })


    },
    // 保存
    onSubmit(formName) {

      this.reports_uploadarr.forEach((item) => {
        formName.reports.forEach((items) => {
          if(item.reportType == items.reportType) {
            this.$refs[item.reportType][0].uploadFiles.map((itemss,indexss) => {
              var path = ""
              if(itemss.path) {
                path= itemss.path;
              }else {
                path= itemss.response.data.path;
              }
              items.imgs.forEach((itemsss,indexsss) => {
                if(itemsss.path == path) {
                  this.drag(items.imgs,indexsss,indexss)
                }
              })
            })
          }
        })

      })

      this.$refs.form.validate((valid) => {
        if(valid) {
          console.log(formName);

          // if(this.upload_load) {
            if(this.$route.query.state == 0) {
              this.$post("operate/appointment/accompany/edit",formName)
                  .then(res => {
                    if(res) {
                      console.log(res)
                      this.$router.push({
                        path: "/OperateWork",
                        query: {
                          activeName: this.$route.query.state
                        }
                      })
                    }
                  })
            }else if(this.$route.query.state == 2){
              this.$post("operate/follow/accompany/edit",formName)
                  .then(res => {
                    if(res) {
                      console.log(res)
                      this.$router.push({
                        path: "/OperateWork",
                        query: {
                          activeName: this.$route.query.state
                        }
                      })
                    }
                  })
            }
          // }else {
          //   this.$message.warning("报告上传未完成不可操作保存");
          // }


        }
      })
    },
    // 预约详情
    get_deta_appointment(y) {
      this.$get("operate/appointment/detail",y,true)
        .then(res => {
          if(res) {
            console.log(res);

            this.deta= res.data;

            // 收据
            if(res.data.receipts.length != 0) {
              res.data.receipts.forEach((item) => {

                let obj= {};
                obj["name"]= item.path.substring(item.path.lastIndexOf('/')+1);
                obj["url"]= item.url;
                obj["path"]= item.path;
                this.fileListimg.push(obj);

                let objs={}
                objs["id"]= item.id;
                objs["path"]= item.path;
                this.form.receipts.push(objs);

              })
            }

            // 报告
            res.data.reports.forEach((item) => {
              item["delReportImgs"]= []
            })
            // 渲染
            this.reports_uploadarr = res.data.reports;
            // 保存数据
            this.form.reports= JSON.parse(JSON.stringify(res.data.reports));


            // if(res.data.reports.length != 0) {
            //   res.data.reports.forEach((item) => {
            //
            //     let obj= {};
            //     obj["name"]= item.path.substring(item.path.lastIndexOf('/')+1);
            //     obj["url"]= item.url;
            //     obj["path"]= item.path;
            //     this.fileList.push(obj);
            //
            //     let objs= {};
            //     objs.id= item.id;
            //     objs.path= item.path;
            //     objs.reportType= item.reportType;
            //     this.form.reports.push(objs);
            //
            //   })
            // }

            if(res.data.status != '0') {
              this.form.status= res.data.status;
            }
            this.form.id= res.data.id;
            this.form.content= res.data.content;
            this.data.id= res.data.id;

          }
        })
    },
    // 随访详情
    get_deta_follow(y) {
      this.$get("operate/follow/detail",y,true)
        .then(res => {
          if(res) {
            console.log(res);

            this.deta= res.data;

            if(res.data.receipts.length != 0) {
              res.data.receipts.forEach((item) => {

                let obj= {};
                obj["name"]= item.path.substring(item.path.lastIndexOf('/')+1);
                obj["url"]= item.url;
                obj["path"]= item.path;
                this.fileListimg.push(obj);

                let objs={}
                objs["id"]= item.id;
                objs["path"]= item.path;
                this.form.receipts.push(objs);

              })
            }

            // 报告
            res.data.reports.forEach((item) => {
              item["delReportImgs"]= []
            })
            // 渲染
            this.reports_uploadarr = res.data.reports;
            // 保存数据
            this.form.reports= JSON.parse(JSON.stringify(res.data.reports));

            // if(res.data.reports.length != 0) {
            //   res.data.reports.forEach((item) => {
            //
            //     let obj= {};
            //     obj["name"]= item.path.substring(item.path.lastIndexOf('/')+1);
            //     obj["url"]= item.url;
            //     obj["path"]= item.path;
            //     this.fileList.push(obj);
            //
            //     let objs= {};
            //     objs.id= item.id;
            //     objs.path= item.path;
            //     objs.reportType= item.reportType;
            //     this.form.reports.push(objs);
            //
            //   })
            // }
            // console.log(this.fileList)

            if(res.data.status != '0') {
              this.form.status= res.data.status;
            }
            this.form.id= res.data.id;
            this.form.content= res.data.content;
            this.data.id= res.data.id;

          }
        })
    },
    // 取消认领
    noClaim() {
      if(this.$route.query.state == 0) {

        this.$post("operate/appointment/unbind/" + this.$route.query.id)
            .then(res => {
              if(res) {
                console.log(res);
                this.$router.push({
                  path: "/OperateWork",
                  query: {
                      activeName: this.$route.query.state
                    }
                })
              }
            })

      }else if(this.$route.query.state == 2) {
        this.$post("operate/follow/unbind/" + this.$route.query.id)
            .then(res => {
              if(res) {
                console.log(res);
                this.$router.push({
                  path: "/OperateWork",
                  query: {
                      activeName: this.$route.query.state
                    }
                })
              }
            })
      }
    },


    // 图片
    closeViewer() {
      this.showViewer= false;
    },
    handleAvatarSuccessPicProductBanner(res) {
      console.log(res);
      let obj= {};
      obj["id"]= null;
      obj["path"]= res.data;
      this.form.receipts.push(obj);
      console.log(this.form);
    },
    beforeAvatarUploadPicProductBanner(file) {
      let arr = file.name.split(".");
      let extension = arr[arr.length-1];
      let extensionList = ["JPG","jpg","jpeg","JPEG","png","PNG","bmp","BMP"];

      if (extensionList.indexOf(extension) < 0) {
        this.$message.warning("只能上传jpg/jpeg/png/bmp文件");
        return false;
      }
    },
    beforeRemoveBanner(file, fileList) {
      console.log(file)
      console.log(fileList)
    },
    handlePictureCardPreview(file) {

      this.viewerImgList= []

      let arr = []
      var indexs = null;
      let leng = this.$refs['uploadProductBanner'].uploadFiles.length;
      this.$refs['uploadProductBanner'].uploadFiles.map((item,index) => {
        if(item.uid == file.uid) {
          indexs= index
        }
      })

      this.$refs['uploadProductBanner'].uploadFiles.map((item,index) => {
        if(index - indexs >= 0) {
          arr[index - indexs]= item.url;
        }else {
          arr[index - indexs + leng]= item.url;
        }
      })

      this.viewerImgList= arr;

      this.showViewer = true;

    },
    handleRemoveProductBanner(file) {
      console.log(file)

      var path = ""
      if(file.path) {
        path= file.path;
      }else {
        path= file.response.data;
      }

      let num= 0;

      this.$refs["uploadProductBanner"].uploadFiles.map(item => {
        if(item.uid == file.uid) {
          this.$refs["uploadProductBanner"].uploadFiles.splice(num,1);
        }
        num ++
      })

      this.form.receipts.forEach((item,index) => {
        console.log(path)
        console.log(item.path == path)
        if(item.path == path) {

          if(item.id) {
            this.form.delReceipts.push(item.id);
            this.form.receipts.splice(index,1);
          }else {
            this.form.receipts.splice(index,1)
            let obj={};
            obj["path"]= item.path;
            this.$post("operate/img/delete",obj)
                .then(res => {
                  if(res) {
                    console.log(res);
                  }
                })
          }
        }
      })

    },



    // pdf
    handleClose() {
      this.updia= false;
    },
    // handlePreview(file) {
    //   console.log(file)
    //   if(file.url) {
    //     window.open(file.url, '_blank','toolbar=yes, width=900, height=700');
    //   }else {
    //     window.open(file.response.data.url, '_blank','toolbar=yes, width=900, height=700');
    //   }
    // },
    // // pdf
    // beforeRemove(file, fileList) {
    //   console.log(file)
    //   console.log(fileList)
    //   console.log(this.form.reports)
    //
    //   if(this.upload_load) {
    //     var path= "";
    //     if(file.path) {
    //       path= file.path;
    //     }else {
    //       if(file.response && file.response.data.path) {
    //         path= file.response.data.path;
    //       }else {
    //         console.log(this.form.reports)
    //         console.log(fileList)
    //
    //         fileList.forEach((item,index) => {
    //           if(item.uid == file.uid) {
    //             fileList.splice(index,1)
    //           }
    //         })
    //
    //         return false;
    //       }
    //     }
    //   }else {
    //     this.$message.warning("报告上传未完成，不可操作删除")
    //     return false;
    //   }
    //
    //
    //   this.form.reports.forEach((item,index) => {
    //     if(item.path == path) {
    //       console.log(item.path)
    //       console.log(path)
    //       if(item.id) {
    //         this.form.delReports.push(item.id);
    //         this.form.reports.splice(index,1);
    //       }else {
    //         this.form.reports.splice(index,1)
    //         let obj={};
    //         obj["path"]= item.path;
    //         this.$post("operate/img/delete",obj)
    //           .then(res => {
    //             if(res) {
    //               console.log(res);
    //             }
    //           })
    //       }
    //     }
    //   })
    //   console.log(this.form);
    //
    // },
    // beforeUpload(file) {
    //   let extension = file.name.split(".")[1];
    //   let extensionList = ["pdf"];
    //   console.log(extension)
    //   if (extensionList.indexOf(extension) < 0) {
    //     this.$message.warning("只能上传pdf文件");
    //     return false;
    //   }else {
    //     this.upload_load= false;
    //   }
    // },
    // onSuccess(res) {
    //   console.log(res)
    //
    //   this.reports.path= res.data.path;
    //   this.upload_load= true;
    //   this.updia= true;
    //   console.log(this.form);
    //
    // },

    // 检测结果上传
    beforeRemove() {

    },
    handlePreview() {

    },
    // 上传前
    beforeUpload(file) {
      let arr = file.name.split(".");
      let extension = arr[arr.length-1];
      let extensionList = ["JPG","jpg","jpeg","JPEG","png","PNG","bmp","BMP"];

      if (extensionList.indexOf(extension) < 0) {
        this.$message.warning("只能上传jpg/jpeg/png/bmp文件");
        return false;
      }
    },
    // 上传成功
    onSuccess(res,item) {
      // console.log(res)
      console.log(item)

      this.form.reports.forEach((items) => {
        if(items.reportType == item.reportType) {
          items.imgs.push(res.data);
          // setTimeout(() => {
          //   item.imgs = items.imgs;
          // },1000)
        }
      })


      console.log(this.form.reports[0].imgs)

      // console.log(this.form.reports)

    },
    // 上移
    changeLeft(file,ref) {
      // items
      this.display_click_left = false;

      // var path = ""
      // if(file.path) {
      //   path= file.path;
      // }else {
      //   path= file.response.data.path;
      // }

      console.log(this.form.reports[0].imgs);

      this.$refs[ref][0].uploadFiles.map((item,index) => {
        if(file.uid == item.uid) {
          if(index != 0) {

            this.$nextTick(() => {
              this.changeSort(this.$refs[ref][0].uploadFiles,index,0)
              this.display_click_left= true;
            })

            // this.form.reports.forEach((it) => {
            //   if(it.reportType == items.reportType) {
            //     it.imgs.forEach((its,indexs) => {
            //       console.log(its)
            //       if(its.path == path) {
            //         console.log(indexs)
            //         this.swapArray(it.imgs,indexs,indexs - 1)
            //         this.$nextTick(() => {
            //           this.changeSort(this.$refs[ref][0].uploadFiles,index,0)
            //           this.display_click_left= true;
            //         })
            //
            //       }
            //     })
            //   }
            // })



          }else {
            this.display_click_left= true;
            this.$message({
              message: '已经是第一张图片',
              type: 'warning'
            });
          }
        }
      })

    },
    // 下移
    changeRight(file,ref) {
      // items
      this.display_click_right= false;

      // var path = ""
      // if(file.path) {
      //   path= file.path;
      // }else {
      //   path= file.response.data.path;
      // }

      console.log(this.form.reports[0].imgs);

      let leng = this.$refs[ref][0].uploadFiles.length - 1;

      this.$refs[ref][0].uploadFiles.map((item,index) => {
        if(file.uid == item.uid) {
          if(index != leng) {


            setTimeout(() => {
              this.changeSort(this.$refs[ref][0].uploadFiles,index,1);
              this.display_click_right = true;
            },200)

            // this.form.reports.forEach((it) => {
            //   if(it.reportType == items.reportType) {
            //     it.imgs.forEach((its,indexs) => {
            //       if(its.path == path) {
            //         // this.changeSort(it.imgs,indexs,1)
            //
            //         setTimeout(() => {
            //           console.log(its.path);
            //           console.log(path)
            //           console.log(indexs)
            //           this.swapArray(it.imgs,indexs,indexs + 1)
            //           this.changeSort(this.$refs[ref][0].uploadFiles,index,1)
            //           this.$nextTick(() => {
            //             this.display_click_right = true;
            //           })
            //         },200)
            //       }
            //     })
            //   }
            // })



          }else {
            this.$message({
              message: '已经是最后一场图片',
              type: 'warning'
            });
            this.display_click_right= true;
          }
        }
      })

    },
    // 图片查看器(打开)
    handlePictureCardPreviewDemo(file,items) {
      this.viewerImgListDemo= []

      let arr = []
      var indexs = null;
      let leng = this.$refs[items.reportType][0].uploadFiles.length;
      this.$refs[items.reportType][0].uploadFiles.map((item,index) => {
        if(item.uid == file.uid) {
          indexs= index
        }
      })

      this.$refs[items.reportType][0].uploadFiles.map((item,index) => {
        if(index - indexs >= 0) {
          arr[index - indexs]= item.url;
        }else {
          arr[index - indexs + leng]= item.url;
        }
      })

      this.viewerImgListDemo= arr;

      this.showViewerDemo = true;
    },
    // 图片查看器(关闭)
    closeViewerDemo() {
      this.showViewerDemo= false;
    },
    // 删除
    handleRemoveProductBannerDemo(file,ref,items) {
      console.log(file)
      console.log(items)
      var path = ""
      if(file.path) {
        path= file.path;
      }else {
        path= file.response.data.path;
      }

      let num= 0;

      this.$refs[ref][0].uploadFiles.map(item => {
        if(item.uid == file.uid) {
          this.form.reports.forEach((it) => {
            if(it.reportType == items.reportType) {
              it.imgs.forEach((its,indexs) => {
                if(its.path == path) {
                  if(its.id != null) {
                    it.delReportImgs.push(its.id)
                  }
                  it.imgs.splice(indexs,1)
                }
              })
              console.log(it.imgs)
            }
          })
          this.$refs[ref][0].uploadFiles.splice(num,1);
        }
        num ++
      })


    },


    // 上传前处理报告类型处理
    form_upload_click(formName) {
      this.$refs.form_upload.validate((valid) => {
        if(valid) {

          let obj= {};
          obj["reportType"]= parseInt(formName.reportType);
          obj["path"]= this.reports.path;
          this.form.reports.push(obj);
          this.reports.path= null;

          this.$refs.form_upload.resetFields();
          this.updia= false;
        }
      })
    },
  },
  // components: {
  //   pdf
  // }
}
</script>

<style lang="less" scoped>
  @import "../../style/Operate/OperateDeta";
</style>
